import { MainSubStructureModel, RenderByPermission, request } from '../../utils';
import type { BaseData } from '../../utils';
import { action, observable } from 'mobx';
import type { FormInstance } from 'antd';
import { Modal, Button, Dropdown, message, Space, Switch } from 'antd';
import React from 'react';

export default class Model {
  constructor() {
    this.initMainSubStructureModel();
    this.getDict();
  }

  @observable public modalOpen = false;

  public mainSubStructureModel: MainSubStructureModel;

  public formRef = React.createRef<FormInstance>();

  @action
  public setModalOpen = () => {
    this.modalOpen = !this.modalOpen;
    if (!this.modalOpen) {
      this.formRef?.current?.resetFields();
    }
  };

  @action
  public onSubmit = () => {
    this.formRef.current?.validateFields()?.then(async (params) => {
      await request({
        method: 'POST',
        url: '/quan/biz/dict/add',
        data: { ...params },
      });
      message.success('新增成功');
      this.setModalOpen();
      this.mainSubStructureModel.onQuery();
    });
  };

  @observable public noteTypeOptions = [];

  @action
  public getDict = async () => {
    const req = await request<any>({
      method: 'GET',
      url: '/quan/sys/dict/type',
      params: { dictType: 'note_type' },
    });
    this.noteTypeOptions = (req?.data || []).map((item) => ({
      label: item.dictName,
      value: item.dictValue,
    }));
  };

  @action
  public onDelete = (ids: string) => {
    Modal.confirm({
      title: '删除提示',
      content: '确认删除吗？',
      okText: '确定',
      cancelText: '取消',
      onOk: () =>
        request({
          method: 'POST',
          url: '/quan/biz/dict/delete',
          data: { ids },
        }).then(() => {
          message.success('删除成功');
          this.mainSubStructureModel.onQuery();
        }),
    });
  };

  @action
  public initMainSubStructureModel = () => {
    this.mainSubStructureModel = new MainSubStructureModel({
      buttons: [
        // {
        //   text: '删除',
        //   permissionId: '4',
        //   handleClick: () => {
        //     const ids = Array.from(this.mainSubStructureModel.gridModel.selectedIds);
        //     if (!ids.length) {
        //       message.warning('请选择要删除的字典');
        //       return;
        //     }
        //     this.onDelete(ids.join(','));
        //   },
        // },
        {
          text: '新增',
          permissionId: '2',
          handleClick: () => {
            this.setModalOpen();
          },
        },
      ],
      pageId: new URLSearchParams(window.location.search)?.get('pageId'),
      grid: {
        columns: [
          {
            key: 'operation',
            name: '操作',
            width: 200,
            formatter: ({ row }) => {
              const pageId = new URLSearchParams(window.location.search)?.get('pageId');
              if (row.userId === '0') {
                return <div />;
              }
              return (
                <div>
                  <RenderByPermission permissionId={`${pageId}_4`}>
                    <Button
                      onClick={() => {
                        this.onDelete(row.id);
                      }}
                      type="link"
                    >
                      删除
                    </Button>
                  </RenderByPermission>
                </div>
              );
            },
          },
          {
            key: 'defName',
            name: '字典名称',
          },
          {
            key: 'defType',
            name: '字典类型',
            formatter: ({ row }) => {
              const [data] = this.noteTypeOptions.filter((item) => item.value === row.defType);
              return <div>{data?.label}</div>;
            },
          },
        ].map((v) => ({
          resizable: true,
          sortable: false,
          ...v,
        })),
        rows: [],
        primaryKeyField: 'id',
        sortByLocal: false,
        showCheckBox: false,
        showEmpty: true,
        showPager: false,
        showGridOrderNo: true,
      },
      hiddenSubTable: true,
      api: {
        onQuery: (params) =>
          request<BaseData<any>>({
            url: '/quan/biz/dict/list',
            method: 'POST',
            data: {},
          }).then((res) => {
            const data: any = { data: { list: res.data } };
            return data;
          }),
      },
    });
    this.mainSubStructureModel.onQuery();
  };
}
