import React from 'react';
import Model from './model';
import { MainSubStructure } from '../../utils';
import { observer } from 'mobx-react';
import styles from './index.less';
import { Form, Modal, Input, Select } from 'antd';

@observer
export default class DictionaryConfiguration extends React.Component {
  constructor(props) {
    super(props);
    this.store = new Model();
  }

  public readonly store: Model;

  render() {
    const { mainSubStructureModel, noteTypeOptions, modalOpen, formRef, setModalOpen, onSubmit } = this.store;
    return (
      <div className={styles.page}>
        <Form
          scrollToFirstError
          ref={formRef}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
        >
          <Modal
            open={modalOpen}
            onCancel={setModalOpen}
            onOk={onSubmit}
            title="新增"
          >
            <div className={styles.modalContent}>
              <Form.Item
                label="字典类型"
                name="defType"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  optionFilterProp="label"
                  options={noteTypeOptions}
                />
              </Form.Item>
              <Form.Item
                label="字典名称"
                name="defName"
                rules={[{ required: true }]}
              >
                <Input autoComplete="off" />
              </Form.Item>
            </div>
          </Modal>
        </Form>
        <MainSubStructure store={mainSubStructureModel} />
      </div>
    );
  }
}
